export const theme = {
  _name: 'aragon-upgrade',
  _appearance: 'light',
  accent: '#00c2ff',
  accentStart: '#01e8f7',
  accentEnd: '#00c2ff',
  disabledContent: '#a0a8c2',
  positive: '#38ccb6',
  positiveSurface: '#ecfaf8',
  negative: '#ff7163',
  contentSecondary: '#8991b1',
  surfaceContentSecondary: '#6f7ca6',
  surfaceSelected: '#fdfefe',
  primary: '#8889BB',
  secondary: '#767D94',
  black: '#212B36',
  whiteCard: 'rgb(255,255,255,0.75)',
}
