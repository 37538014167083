import React from 'react'

function LockIcon(): JSX.Element {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5555 8.25H14.2222V6.41665C14.2222 2.87848 11.431 0 8 0C4.569 0 1.77779 2.87848 1.77779 6.41665V8.25H0.444458C0.198792 8.25 0 8.455 0 8.70835V20.1667C0 21.1778 0.797292 22 1.77779 22H14.2223C15.2027 22 16 21.1778 16 20.1667V8.70835C16 8.455 15.8012 8.25 15.5555 8.25ZM9.33075 17.8244C9.34462 17.9538 9.30429 18.0836 9.22008 18.1807C9.13588 18.2778 9.01521 18.3333 8.88892 18.3333H7.11113C6.98483 18.3333 6.86417 18.2778 6.77996 18.1807C6.69575 18.0836 6.65538 17.9538 6.66929 17.8244L6.94967 15.2244C6.49438 14.8829 6.22225 14.3426 6.22225 13.75C6.22225 12.7389 7.01954 11.9167 8.00004 11.9167C8.98054 11.9167 9.77783 12.7389 9.77783 13.75C9.77783 14.3426 9.50571 14.8829 9.05042 15.2244L9.33075 17.8244ZM11.5555 8.25H4.44446V6.41665C4.44446 4.39489 6.0395 2.75 8 2.75C9.9605 2.75 11.5555 4.39489 11.5555 6.41665V8.25V8.25Z"
        fill="#6675A5"
      />
    </svg>
  )
}

export default LockIcon
